#apartmant__list {
  display: flex;
  flex-direction: column;

  h1 {
    @include title;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 600px) {
  .apartmant__list {
    h1 {
      font-size: $mobileFontSizeTitle;
    }
  }
}

.apartmant-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
