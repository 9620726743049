.testimonials {
  h2 {
    @include title;
  }

  .carousel-control-prev-icon {
    background-image: url("../../assets/images/arrow_left.png");
  }

  .carousel-control-next-icon {
    background-image: url("../../assets/images/arrow_left.png");
    transform: rotate(180deg);
  }

  .testimonials__card {
    width: 100%;
    display: flex;
    border: 1px solid lightgray;
    padding: 50px;
    transition: 0.4s;
    background-color: white;

    &-left {
      @include flexCenter;
      flex: 0.35;
      flex-direction: column;

      img {
        height: 15px;
        width: 30px;
      }
    }

    &-right {
      flex: 0.65;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
      max-width: 500px;
    }

    &-avatar {
      height: 40px;
      aspect-ratio: 1;
      border-radius: 50%;
      background-color: $grayDark;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-flagbox {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      padding-bottom: 20px;

      h4 {
        margin-bottom: 0;
        margin-right: 0.5rem;
      }
    }

    &-country {
      font-size: 1rem;
      margin-bottom: 5px;
      font-style: italic;
    }

    &-date {
      font-style: italic;
      font-size: 1rem;
    }

    &-rating {
      font-size: 1.4rem;
      span {
        background-color: $grayDark;
        color: white;
        padding: 8px;
        margin-left: 5px;
      }
    }

    &-description {
      @include paragraph;
      i {
        padding: 0 0.5rem;
        color: $gray;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .testimonials__card {
    flex-direction: column;
    align-items: center;
    padding: 10px 30px !important;

    &-right {
      margin-top: 1.2rem;
    }

    &-description {
      height: 200px;
      padding-right: 25px;
      overflow-y: scroll;
    }
  }
}

@media only screen and (max-width: 499px) {
  .testimonials {
    h2 {
      font-size: $mobileFontSizeTitle;
    }
  }
}
