* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fbfbfb;
  font-size: 16px;
  font-family: $mainFont;
}

.hand {
  font-family: $handFont;
  font-weight: 400;
  font-style: normal;
  color: black;
}
