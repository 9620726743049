.welcome {
  height: 100vh;

  &__background {
    background-image: url("../../assets/images/house.jpg");
    -webkit-filter: grayscale(60%);
    filter: grayscale(60%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0px;
    left: 0px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      /* background-image: linear-gradient(rgb(177, 177, 177), black); */
      opacity: 0.6;
    }
  }
}

@media only screen and (max-width: 550px) {
  .welcome {
    height: 60vh;
  }
}
