.apartmant__card {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 5vh 0;
  font-weight: 400;

  h3 {
    font-weight: 400;
  }

  &-article {
    margin-left: -50px;
    border: 1px solid lightgray;
    padding: 2rem 1.5rem 2rem;
  }

  &-image {
    max-height: 400px;

    &.premium {
      border-top: 7px solid $gold;
    }
  }

  button {
    @include mainButton;

    &:hover {
      background-color: $grayBlack;
    }
  }
}

.card__content {
  position: relative;
  background-color: white;
}

.card__content:hover {
  box-shadow: 0 16px 24px $grayDark;
}

.card__pricing {
  position: absolute;
  background: $grayDark;
  width: 60px;
  height: 88px;
  right: 1.5rem;
  top: -1rem;
  padding-top: 1.25rem;
  text-align: center;
  border-bottom: unset;
}

.card__pricing-symbol {
  font-size: 0.75rem;
}

.card__pricing-number {
  font-size: 1.25rem;
}

.card__pricing-month {
  display: block;
  font-size: 0.625rem;
}

.card__pricing-number,
.card__pricing-month {
  color: #fff;
}

.card__pricing::after,
.card__pricing::before {
  content: "";
  position: absolute;
}

.card__pricing::after {
  width: 100%;
  height: 14px;
  background-color: #fff;
  left: 0;
  bottom: 0;
  clip-path: polygon(0 100%, 50% 0, 100% 100%);
}

.card__pricing::before {
  width: 14px;
  height: 16px;
  background-color: $grayDark;
  top: 0;
  left: -14px;
  clip-path: polygon(0 100%, 100% 0, 100% 100%);
  border-bottom: unset;
}

@media only screen and (max-width: 1200px) {
  .apartmant__card {
    flex-direction: column;

    &-image {
      width: 100%;
      min-width: 340px;
    }

    &-article {
      margin-left: 0;
      margin-top: -15px;
    }

    &-icons {
      padding-top: 10px;
      max-width: 400px;
      margin: 0 auto;
      i {
        font-size: 1.3rem;
      }

      .iconbox {
        span {
          font-size: 1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 499px) {
  .apartmant__card-name {
    max-width: 280px;
  }
}
