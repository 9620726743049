footer {
  background-color: $gray;

  h1 {
    color: $grayDark;
    font-size: 1.5rem;
  }

  .footer__box {
    display: flex;
    padding: 20px;
    margin-top: 20px;
  }

  .footer-left {
    flex: 0.4;
    i {
      color: rgba(0, 0, 0, 0.493);
      margin-right: 1rem;
    }

    address {
      p {
        margin-bottom: 0.5rem;
        font-size: 1rem;
        color: $grayDark;
      }

      img {
        display: inline-block;
        margin-right: 10px;
      }

      a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
      }
    }
  }

  .footer-right {
    flex: 0.6;
    font-size: 1rem;
    color: $grayDark;
  }

  .social-links {
    i {
      color: #476472;
      font-size: 2rem;
      margin-right: 1rem;
      transition: 0.5s;

      &:hover {
        color: black;
        cursor: pointer;
      }
    }
  }

  .footer-promotion {
    background-color: $grayDark;
    text-align: center;

    p {
      margin-bottom: 0;
      padding: 5px 0;
      color: $gray;
      font-size: 0.8125rem;

      span,
      a {
        color: $orange;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .footer__box {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  footer {
    h1 {
      font-size: 1.125rem;
    }
    p {
      font-size: 0.8125rem;
    }

    .footer-left {
      address {
        p {
          font-size: 0.8125rem;
        }
      }
    }
  }
}
