.apartmant__details {
  margin-top: 100px;

  .gallery {
    display: flex;
    padding: 10px;

    &-left {
      display: flex;
      flex-direction: column;

      .gallery-img {
        height: 50%;
        border: 5px solid white;
      }
    }

    &-right {
      flex: 1;
      img {
        height: 100%;
        width: 100%;
        border: 5px solid white;
      }
    }
  }
  .details-bar {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0 2px 13px -5px $grayDark;
    padding: 15px 10px;
    font-weight: 500;
    margin: 50px 0;
    background-color: white;

    h2 {
      margin: 0;
      margin-left: 5vw;
      font-size: 1rem;
      text-transform: uppercase;
      color: #656565 !important;
    }

    button {
      @include mainButton;
      margin-right: 5vw;
    }
  }

  .section-info {
    h2 {
      @include title;
    }
  }

  .details-information-container {
    margin: 50px 0;
    border: 1px solid lightgray;
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
    ul {
      display: flex;
      justify-content: center;
      margin-bottom: 0;
      li {
        padding: 10px 20px;
        margin: 0 5px;
        text-transform: uppercase;
        color: $grayLight;
        font-style: italic;
      }
    }

    p {
      margin-bottom: 0;
    }

    .details-review {
      p {
        color: $grayLight;
        text-transform: uppercase;
        max-width: 400px;
      }
    }
  }

  .apartmant-description {
    @include paragraph;
    max-width: 600px;
    margin: 0 auto;
  }

  .beaches-title {
    @include title;
  }

  .closest-beaches {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  iframe {
    width: 100%;
    height: 60vh;
  }
}

@media only screen and (max-width: 1500px) {
  .apartmant__details {
    .gallery {
      flex-direction: column;
      height: auto;
    }

    .details-bar {
      display: none;
    }

    .details-information-container {
      ul {
        flex-direction: column;
        padding-left: 0px;

        li {
          padding: 3px 6px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1501px) {
  .apartmant__details {
    .gallery {
      height: 70vh;
    }
  }
}
