.icons-bar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
  max-width: 600px;

  i {
    color: $grayDark;
    font-size: 1.7rem;
  }

  .iconbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 700;
      font-size: 1.2rem;
      padding: 5px 0;
      color: $grayDark;
    }
  }
}

.icons-bar-new {
  display: flex;
  p {
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
    color: #656565 !important;
    margin: 0;
    padding: 0 30px;
  }

  i {
    margin: 0 15px;
    color: $grayDark;
  }
}
