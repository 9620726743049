@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin title {
  font-size: 1.7rem;
  font-weight: 300;
  color: $black;
  text-transform: capitalize;
  margin-top: 7vh;
}

@mixin paragraph {
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  line-height: 133%;
  color: #464646;
}

@mixin mainButton {
  background-color: $grayDark;
  color: $white;
  border: unset;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
