.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  padding: 10px;
  width: 100%;
  transition: 0.5s ease-in;
  z-index: 10;
  background-color: transparent;
  color: white;

  h1 {
    color: white;
  }

  &.nav__black {
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    h1 {
      color: black;
    }
  }

  .nav-img-container {
    display: flex;
    align-items: center;
    background: white;
    padding: 0 5px;
    height: 65px;
  }

  .nav-logo-img {
    transform: scale(0.5);

    &.logo-name {
      transform: scale(0.8);
    }
  }
}

@media only screen and (max-width: 600px) {
  .nav {
    padding: 5px 0 5px 5px;

    .nav-img-container {
      max-height: 50px;
    }

    .nav-logo-img.logo-name {
      margin-left: -2rem;
    }
  }
}
