.beach-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid lightgray;
  background-color: white;
  padding: 1rem;
  margin: 0.5rem;
  width: 100%;
  max-width: 350px;

  &-icon {
    padding: 20px;
    font-size: 0.2rem;
    background-color: $grayDark;
    @include flexCenter;
  }

  &-info {
    padding-left: 10px;
    text-align: start;
    h5,
    p {
      margin-bottom: 0;
      font-family: $font4;
    }

    p {
      margin: 5px 0;
      i {
        color: goldenrod;
        padding-right: 5px;
      }
    }

    .icon-star {
      color: $grayDark;
      margin-left: 3px;
    }
  }
}
