.apartmant {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 100%;
  min-height: 450px;
  justify-self: center;
  margin: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;

  &-icon {
    background-color: #fff7;
    color: white;
    font-size: 1.3rem;
    padding: 10px 12px 6px;
    width: 45px;
    height: 40px;
    border-radius: 0 4px 4px 0;
    margin-top: 1rem;
    @include flexCenter;
  }

  &-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: white;
    font-size: 0.8rem;

    &::after {
      position: absolute;
      content: "";
      height: 80px;
      width: 100%;
      background-image: linear-gradient(gray, black);
      opacity: 0.5;
      z-index: 1;
    }

    .infobox {
      padding-left: 1rem;
      z-index: 2;

      h4 {
        font-family: $mainFont;
        font-size: 0.9rem;
        font-weight: 900;
        text-transform: uppercase;
      }
    }

    .info {
      display: flex;

      p {
        margin-left: 5px;
        font-weight: 900;
      }
    }

    .icon {
      z-index: 2;
      i {
        padding: 1rem;
        font-size: 1.8rem;
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .apartmant {
    margin: 5px 0;
  }

  .icon {
    i {
      font-size: 1.5rem;
    }
  }
}
